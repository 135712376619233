import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import HeroSection from "../../components/HeroSection";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

const ProgramDetailsSection = () => (
  <Section>
    <div className="flex flex-col items-center md:items-stretch md:flex-row md:justify-center">
      <div className="flex flex-col justify-center text-center md:text-left md:mr-3 lg:mr-6">
        <p className="text-xl uppercase lg:text-2xl">What is</p>
        <p className="text-3xl font-bold uppercase md:mr-0 lg:text-4xl">
          Self-improvement?
        </p>
      </div>
      <div className="hidden mr-3 border-l-2 border-black border-solid md:block lg:mr-6"></div>
      <div className="flex flex-col justify-center mt-4 text-center md:text-left lg:text-lg md:mt-0">
        <p className="w-64">
          It is all the actions we take and the new things we learn, alone or in
          a group, so we become more skilled and come closer to our goals.
        </p>
      </div>
    </div>
    <div className="std-mt md:text-lg">
      <p className="text-lg font-bold md:text-xl">Why being active?</p>
      <p className="mt-4">
        There are a lot of projects and activities that can help you improve
        your skills and personal life, as well as drive interest from employers:
        to volunteer for an association, design a blog, develop an app, start a
        portfolio project, build mockups or prototypes, join a collaborative
        project, organize events, offer workshops, create videos, learn a new
        subject, do craft activities, fundraise for a cause, start a club…
      </p>
    </div>
    <div className="std-mt md:text-lg">
      <p className="text-lg font-bold md:text-xl">
        Examples of events and activities for self-improvement at Perform On Top
      </p>
      <ul className="pl-2 mt-4 list-disc list-inside">
        <li className="mt-2">
          <span className="font-bold">Social activities.</span> Walks, meet-ups,
          creative workshops: each month we offer you to experience something
          new.
        </li>
        <li className="mt-2">
          <span className="font-bold">Speaking Swedish.</span> Whatever your
          level of the Swedish language is, we meet on a regular basis to
          practice speaking Swedish. Join our{" "}
          <a
            className="text-orange-700 underline hover:text-blue-700"
            href="https://www.facebook.com/groups/1293566301026827/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Språkkafe with Perform On Top
          </a>{" "}
          group on Facebook to practice basic to intermediate Swedish. If you
          you need to polish your advanced Swedish, join the meetup{" "}
          <a
            className="text-orange-700 underline hover:text-blue-700"
            href="https://www.meetup.com/intercambio/events/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Remote Advanced SWEDISH Conversation
          </a>{" "}
          every Sunday, 15:00-16:30, online.
        </li>
        <li className="mt-2">
          <span className="font-bold">
            Cultural workshops and invited guest speakers.
          </span>{" "}
          Depending on needs and opportunities, we organize cultural workshops,
          self-improvement lectures, and events.
        </li>
        <li className="mt-2">
          <span className="font-bold">Remote English debate club.</span> If you
          need to learn to make compelling arguments, scrutinize others' ideas,
          defend yourself from attacks and persuade audience in your point of
          view &ndash; join the{" "}
          <a
            className="text-orange-700 underline hover:text-blue-700"
            href="https://www.meetup.com/intercambio/events/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Remote English Debate Club
          </a>{" "}
          meetup, every Tuesday, 18:00, online.
        </li>
      </ul>
    </div>
  </Section>
);

const ProgramPage = ({ data }) => {
  return (
    <>
      <HeroSection
        bgImg={data.heroBgImg}
        bgImgAltTxt="Hero background people put hands together"
        title="Self-improvement"
        subtitle="Individual growth, empowerment, engagement"
        shaderClassName="opacity-30"
      />
      <main>
        <ProgramDetailsSection />
      </main>
    </>
  );
};

ProgramPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const programQuery = graphql`
  query SelfImprovementQuery {
    heroBgImg: file(relativePath: { eq: "self-improvement-hero-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default ProgramPage;
